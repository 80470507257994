import {
  type LazyQueryExecFunction,
  type LazyQueryHookOptions,
  type OperationVariables,
  type QueryResult,
  type TypedDocumentNode,
  useLazyQuery,
  useQuery,
} from "@apollo/client";
import dayjs from "dayjs";

import { useGlobalContext } from "~/context/global";
import { getServerSideApollo } from "~/utils/graphql";
import { convertToSimplifiedChinese } from "~/utils/i18n";

let scrollTo: HTMLInputElement | null;

export const useHandleScrollTo = () => {
  const { isDesktop, DESKTOP_HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } =
    useGlobalContext();

  const handleScrollTo = (key: string) => {
    if (typeof window !== "undefined") {
      scrollTo = document?.getElementById(key) as HTMLInputElement | null;
    }

    scrollTo &&
      window.scrollTo({
        top:
          scrollTo?.offsetTop -
          (isDesktop ? DESKTOP_HEADER_HEIGHT : MOBILE_HEADER_HEIGHT) -
          10,
        behavior: "smooth",
      });
  };

  return { handleScrollTo };
};

export const debounce = <T extends any[]>(
  func: (...args: T) => void,
  delay: number
) => {
  let timerId: ReturnType<typeof setTimeout> | null = null;
  return (...args: T) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const generateURL = (
  type: string,
  id: string | undefined,
  permalink?: string,
  region?: string,
  locale?: string
): string => {
  if (!id) return "/";

  const OLD_ID_PREFIX = "00000000000000000";
  const cleanedId = id.replace(OLD_ID_PREFIX, "");

  const cleanedPermalink = cleanPermalink(permalink);

  return convertToSimplifiedChinese(
    `/${type}/${cleanedId}/${cleanedPermalink}`,
    locale
  );
};

const cleanPermalink = (permalink: string | undefined): string => {
  if (!permalink) return "";

  // Remove first and last character if they match the pattern
  const trimmedPermalink = permalink
    .replace(/^[^\w\u4e00-\u9fff]|_/, "")
    .replace(/[^\w\u4e00-\u9fff]|_$/, "");

  // Replace spaces and other undesired characters with dashes
  return trimmedPermalink
    .replace(/\s+/g, "-")
    .replace(/[^\w\u4e00-\u9fff]/g, "-")
    .replace(/-+/g, "-");
};

const removeFirstAndLastCharacterIfMatch = (
  inputString: string,
  regex: RegExp
) => {
  if (typeof inputString === "string" && inputString.length > 0) {
    // Check if the first character matches the given regex
    if (inputString[0].match(regex)) {
      inputString = inputString.substring(1);
    }

    // Check if the last character matches the given regex
    if (inputString.slice(-1).match(regex)) {
      inputString = inputString.slice(0, -1);
    }
  }

  // Return the modified or original string based on matches or invalid input
  return inputString;
};

export const generateSlug = (slug: string) => {
  const genSlug = removeFirstAndLastCharacterIfMatch(
    slug,
    /[^\w\u4e00-\u9fff]|_/
  ).replace(/\s+/g, "");

  return genSlug?.replace(/[^\w\u4e00-\u9fff]|_/g, "-").replace(/-+/g, "-");
};

export const generateLoaderID = (id: string | undefined): string => {
  if (!id) return "";
  const OLD_ID_PREFIX = "00000000000000000";
  const result = id?.length === 7 ? `${OLD_ID_PREFIX}${id}` : id;
  return result;
};

export const generateId = (id: string | undefined): string => {
  if (!id) return "";
  // id length: 24;
  const wholeIdSize = 24;
  if (id.length === wholeIdSize) return id;

  const idSize = id?.length;
  const prefixSize = wholeIdSize - idSize;
  let prefix = "";
  for (let i = 0; i < prefixSize; i++) {
    prefix += "0";
  }
  const result = `${prefix}${id}`;
  return result;
};

export async function generateFetchAndProcessData<
  TData = Record<string, unknown>,
  TVariables extends OperationVariables = OperationVariables,
>(
  option: {
    query: TypedDocumentNode<TData, TVariables>;
    variables: TVariables;
  },
  request: Request,
  dataKey: keyof TData,
  locale: string
) {
  const apollo = await getServerSideApollo(request);
  const { data } = await apollo.query({
    query: option.query,
    variables: option.variables,
    fetchPolicy:
      process.env.NODE_ENV === "production" ? "cache-first" : "no-cache",
  });

  const operationResult = data?.[dataKey];
  if (!operationResult) return null;

  const convertedData = convertToSimplifiedChinese(operationResult, locale);

  return convertedData;
}

export const useGenerateUseFetchAndProcessData: <
  TData = Record<string, unknown>,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: TypedDocumentNode<TData, TVariables>,
  variables: TVariables
) => QueryResult<TData, TVariables> = (query, variables) => {
  const { apolloClient } = useGlobalContext();

  const result = useQuery(query, {
    client: apolloClient,
    variables: variables,
    fetchPolicy: "cache-first",
  });

  return result;
};

export const useLazyGenerateUseFetchAndProcessData: <
  TData = Record<string, unknown>,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: TypedDocumentNode<TData, TVariables>,
  variables?: TVariables,
  options?: LazyQueryHookOptions<TData, TVariables>
) => [
  fetch: LazyQueryExecFunction<TData, TVariables>,
  QueryResult<TData, TVariables>,
] = (query, variables = undefined, options = {}) => {
  const { apolloClient } = useGlobalContext();

  return useLazyQuery(query, {
    client: apolloClient,
    variables: variables,
    fetchPolicy: "cache-first",
    onCompleted: (data) => {
      console.log("🚀 ~ data:", data);
    },
    ...options,
  });
};

export const returnUniqueItems = (items: unknown[], key: string) => {
  const uniqueItems = items
    .filter((item) => !!item)
    .reduce((acc: any[], current: any) => {
      // Check if we've already encountered an event with the same id
      const isDuplicate = acc.some(
        (item) => item?.[`${key}`] === current?.[`${key}`]
      );

      // If it's not a duplicate, include it in the accumulation
      if (!isDuplicate) {
        acc.push(current);
      }

      return acc;
    }, [] as Event[]);

  return uniqueItems;
};

export const convertUTagsToLinkTags = (content: any) => {
  // This regular expression finds all <u> tags and captures the URL within them.
  const regex = /<u>(https?:\/\/.*?)<\/u>/g;
  // Replace <u> tags with <a> tags
  return content?.replace(
    regex,
    '<a href="$1" target="_blank" ref="noreferrer"><u>$1</u></a>'
  );
};

export const checkIsInternal = (request: Request): Boolean => {
  let isInternal: Boolean = true;

  const referrer = request.headers.get("referer");
  const host = request.headers.get("host");

  if (referrer && referrer.includes(host as string)) {
    isInternal = true;
  } else {
    isInternal = false;
  }

  return isInternal;
};

export const convertJsonToUrlParams = (json: any): string => {
  const params: string[] = [];

  for (const key in json) {
    if (
      json.hasOwnProperty(key) &&
      Array.isArray(json[key]) &&
      json[key].length > 0
    ) {
      params.push(`${key}=${json[key].join(",")}`);
    }
  }

  return `?${params.join("&")}`;
};

export const convertStringToTimeString = (text: string): string => {
  if (!text) {
    return "";
  }

  switch (text) {
    case "today":
      return dayjs().startOf("day").format("YYYY-MM-DD");
    case "tomorrow":
      return dayjs().add(1, "day").startOf("day").format("YYYY-MM-DD");

    case "this-weekend":
      return `${dayjs()
        .weekday(6)
        .startOf("day")
        .format("YYYY-MM-DD")},${dayjs()
        .weekday(7)
        .startOf("day")
        .format("YYYY-MM-DD")}`;

    case "custom-date":
      return `${dayjs().startOf("day").format("YYYY-MM-DD")},${dayjs()
        .add(1, "day")
        .startOf("day")
        .format("YYYY-MM-DD")}`;
  }

  return text;
};

export const convertTimeStringToString = (text: string): string => {
  if (!text) {
    return "";
  }

  if (text?.includes(",")) {
    const splitInput = text?.split(",");
    const startDate = splitInput[0]?.replace("custom-date-", "");
    const endDate = splitInput[1];

    const isSameStartDate = dayjs(startDate).isSame(
      dayjs().weekday(6).startOf("day"),
      "day"
    );

    const isSameEndDate = dayjs(endDate).isSame(
      dayjs().weekday(7).startOf("day"),
      "day"
    );

    if (isSameStartDate && isSameEndDate) {
      return "this-weekend";
    } else {
      return "custom-date";
    }
  } else {
    const isToday = dayjs(text).isSame(dayjs().startOf("day"), "day");

    const isTomorrow = dayjs(text).isSame(
      dayjs().add(1, "day").startOf("day"),
      "day"
    );

    if (isToday) {
      return "today";
    }

    if (isTomorrow) {
      return "tomorrow";
    }
  }

  return text;
};

export const hkTimeZone = (date: string) => {
  if (!date) {
    return dayjs().add(8, "hour").tz("Asia/Hong_Kong").toISOString();
  }
  return dayjs(date).add(8, "hour").tz("Asia/Hong_Kong").toISOString();
};

export const transformArrayToObject = (array: string[]) => {
  const result: any = {};
  array.forEach((item: string) => {
    const [key, value] = item.split("=", 2); // Split by the first occurrence of '='
    result[key.replace(" ", "")] = value.replace(" ", "");
  });
  return result;
};
