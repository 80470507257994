import { $ } from "~/zeus";
import { typedGql } from "~/zeus/typedDocumentNode";

const addEventViewMutation = typedGql("mutation")({
  AddEventView: [
    {
      id: $("id", "String!"),
      type: $("type", "String!"),
    },
    true,
  ],
});

export default addEventViewMutation;
