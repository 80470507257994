import { $ } from "~/zeus";
import { typedGql } from "~/zeus/typedDocumentNode";

const DistrictsQuery = typedGql("query")({
  Districts: [
    {
      locale: $("locale", "LocaleInputType"),
      fallbackLocale: $("fallbackLocale", "FallbackLocaleInputType"),
      limit: $("limit", "Int"),
      where: $("where", "District_where"),
    },
    {
      docs: {
        id: true,
        name: true,
        level: true,
        value: true,
        children: [
          {},
          {
            id: true,
            name: true,
            level: true,
            value: true,
            children: [
              {},
              {
                id: true,
                name: true,
                level: true,
                value: true,
              },
            ],
          },
        ],
      },
    },
  ],
});

export default DistrictsQuery;
