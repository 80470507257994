import { Fragment } from "react";
//@ts-ignore
import zhCnZhTw from "zh_cn_zh_tw";

export const injectParam = (
  str: string | any[],
  params: Record<string, any>
): React.ReactNode[] => {
  return Object.entries(params)
    .reduce(
      (_arr, [toFind, toReplace]) => {
        return _arr.reduce((_newArr: any, part) => {
          if (typeof part !== "string") return [..._newArr, part];

          const result = part
            .split(`[[${toFind}]]`)
            .reduce((_subParts: any, _subpart, i) => {
              return i > 0 ? [..._subParts, toReplace, _subpart] : [_subpart];
            }, []);

          return [..._newArr, ...result];
        }, []);
      },
      Array.isArray(str) ? str : [str]
    )
    .filter((comp) => !!comp)
    .map((comp, index) => <Fragment key={index}>{comp}</Fragment>);
};

interface AnyObj {
  [key: string]: any;
}

export function convertToSimplifiedChinese(
  obj: string,
  locale?: string
): string;
export function convertToSimplifiedChinese<T extends Record<string, any>>(
  obj: T,
  locale?: string
): T;
export function convertToSimplifiedChinese<T extends any[]>(
  obj: T,
  locale?: string
): T;
export function convertToSimplifiedChinese(obj: any, locale?: string): any {
  if (locale !== "cn") {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => convertToSimplifiedChinese(item, locale));
  }
  if (obj !== null && typeof obj === "object") {
    const newObj: AnyObj = {};
    Object.keys(obj).forEach((key) => {
      newObj[key] = convertToSimplifiedChinese(obj[key], locale);
    });
    return newObj;
  }
  if (typeof obj === "string") {
    return zhCnZhTw?.convertToSimplifiedChinese(obj);
  }
  return obj;
}
