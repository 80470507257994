export const mediaFragment = {
    id: true,
    url: true,
    alt: true,
    sizes: {
      small: { url: true },
      medium: { url: true },
      large: { url: true },
      small_jpeg: { url: true },
      medium_jpeg: { url: true },
      large_jpeg: { url: true },
    },
  }