import { $ } from "~/zeus";
import { typedGql } from "~/zeus/typedDocumentNode";

const addPostViewMutation = typedGql("mutation")({
  AddPostView: [
    {
      id: $("id", "String!"),
      type: $("type", "String!"),
    },
    true,
  ],
});

export default addPostViewMutation;
