import { $ } from "~/zeus";
import { FallbackLocaleInputType } from "~/zeus";
import { mediaFragment } from "~/zeus/fragments";
import { typedGql } from "~/zeus/typedDocumentNode";

const GetCriteriaSettingsQuery = typedGql("query")({
  GetCriteriaSettings: [
    {
      locale: $("locale", "LocaleInputType"),
      fallbackLocale: FallbackLocaleInputType.zh,
      where: $("where", "CriteriaSetting_where"),
      limit: $("limit", "Int"),
    },
    {
      docs: {
        criteria: {
          date: [
            {},
            {
              id: true,
              name: true,
              value: true,
            },
          ],
          audience: [
            {},
            {
              id: true,
              name: true,
              value: true,
              icon: [{}, mediaFragment],
            },
          ],
          category: [
            {},
            {
              id: true,
              name: true,
              value: true,
              icon: [{}, mediaFragment],
            },
          ],
          tag: [
            {},
            {
              id: true,
              name: true,
              value: true,
            },
          ],
          region: true,
        },
      },
    },
  ],
});

export default GetCriteriaSettingsQuery;
