import { $ } from "~/zeus";
import { typedGql } from "~/zeus/typedDocumentNode";

const addBannerViewMutation = typedGql("mutation")({
  AddBannerView: [
    {
      id: $("id", "String!"),
      type: $("type", "String!"),
    },
    true,
  ],
});

export default addBannerViewMutation;
